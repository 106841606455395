import Vue from "vue";
import VueRouter from "vue-router";
import { decrypto } from "../utils/Crypto";
import store from "../store/index";
import localStorageManager from "../services/localStorageManager";
Vue.use(VueRouter);

// admin yetki kontrolü
const beforeAdminEnter = (from, to, next) => {
  try {
    store.commit("SET_REDIRECT_URL", from);
    const user = localStorageManager.userAuth.getUser();
    const decryptoData = decrypto(user);
    if (decryptoData.role === "Admin" || decryptoData.role === "Manager")
      return next();

    return next({ name: "admin-anmeldung" });
  } catch (error) {
    store.commit("SET_REDIRECT_URL", from);
    return next({ name: "admin-anmeldung" });
  }
};

// kullanıcı yetki kontrolü
const beforeUserEnter = (to, from, next) => {
  const user = localStorageManager.userAuth.getUser();
  //here you can set if condition
  if (user !== null) {
    //redirect to other route
    return next();
  } else {
    store.commit("SET_REDIRECT_URL", from);
    return next({ path: "anmeldung" });
  }
};

const beforeLoginPageEnter = (to, from, next) => {
  const user = localStorageManager.userAuth.getUser();
  if (user !== null) {
    //redirect to other route
    return next({ path: "start" });
  } else {
    return next();
  }
};

const routes = [
  {
    path: "/",
    component: () => import("../views/DashboardPage.vue"),
  },
  {
    path: "/mobile-app",
    component: () => import("../views/MobileUserAgent.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardPage.vue"),
  },
  {
    path: "/registrieren",
    name: "registrieren",
    component: () => import("../views/StepPage.vue"),
  },
  {
    path: "/agb",
    name: "agb",
    component: () => import("../views/TermsAndConditionsPage.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/PrivacyPolicyPage.vue"),
  },
  {
    path: "/cancel-condition",
    name: "cancel-condition",
    component: () => import("../views/CancelConditionsPage.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () => import("../views/ImprintPage.vue"),
  },
  {
    path: "/steuertipps",
    name: "steuertipps",
    component: () => import("../views/TaxGuidePage.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/FaqPage.vue"),
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/Support.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/career",
    name: "career",
    component: () => import("../views/CareerPage.vue"),
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("../views/PartnerShipPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/ForgotPasswordPage.vue"),
  },
  {
    path: "/new-password",
    name: "new-password",
    component: () => import("../views/NewPasswordPage.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/PaymentReturn.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactPage.vue"),
  },
  {
    path: "/cookie",
    component: () => import("../views/Cookie.vue"),
  },
  {
    path: "/complete-register",
    component: () => import("../views/CompleteRegister.vue"),
  },
  {
    path: "/payments",
    component: () => import("../views/Payments.vue"),
  },
  {
    path: "/pay/:userId/:taxYear",
    component: () => import("../views/SinglePay.vue"),
  },

  {
    path: "/admin/anmeldung",
    name: "admin-anmeldung",
    component: () => import("../views/Admin/Login.vue"),
  },
  {
    path: "/admin/",
    name: "admin-layout",
    component: () => import("../layouts/admin.vue"),
    beforeEnter: beforeAdminEnter,
    redirect: "/admin/kunden",
    children: [
      {
        path: "kunden",
        name: "kunden",
        component: () => import("../views/Admin/Customers.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/Admin/Users.vue"),
      },
      {
        path: "trash-users",
        name: "trash-users",
        component: () => import("../views/Admin/TrashUser.vue"),
      },
      {
        path: "logs",
        name: "logs",
        component: () => import("../views/Admin/Logs.vue"),
      },
      {
        path: "admins",
        name: "admins",
        component: () => import("../views/Admin/Admins.vue"),
      },
      {
        path: "campaigns",
        name: "campaigns",
        component: () => import("../views/Admin/Campaigns.vue"),
      },
      {
        path: "campaigns/user/:id",
        name: "campaignsUser",
        component: () => import("../views/Admin/CampaignUsers.vue"),
      },
      {
        path: "kunden/:id",
        name: "kunden-detail",
        component: () => import("../views/Admin/CustomerYears.vue"),
      },
      {
        path: "kunden/:id/:year",
        name: "kunden-detail-files",
        component: () => import("../views/Admin/CustomerDetail.vue"),
      },
      {
        path: "kunden/:id/:year/:type",
        name: "kunden-detail-files",
        component: () => import("../views/Admin/CustomerDetailFiles.vue"),
      },
    ],
  },

  {
    path: "/anmeldung",
    name: "anmeldung",
    component: () => import("../views/LoginPage.vue"),
    beforeEnter: beforeLoginPageEnter,
  },
  {
    path: "/start",
    name: "start",
    component: () => import("../views/StartPage.vue"),
    beforeEnter: beforeUserEnter,
  },
  {
    path: "/informationen",
    name: "informationen",
    component: () => import("../views/PersonInformationPage.vue"),
    beforeEnter: beforeUserEnter,
  },
  {
    path: "/tax-returns",
    name: "taxReturns",
    component: () => import("../views/TaxReturnsPage.vue"),
    beforeEnter: beforeUserEnter,
  },
  {
    path: "/kontoeinstellungen",
    name: "kontoeinstellungen",
    component: () => import("../views/AccountSettingsPage.vue"),
    beforeEnter: beforeUserEnter,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
