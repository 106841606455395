import Vue from "vue";
import Vuex from "vuex";

import User from "./modules/User";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    webView: false,
    webViewApp: false,
    returnUrl: null,
    os: getOS(),
  },
  getters: {
    getWebView(state) {
      return state.webView;
    },
    getWebViewApp(state) {
      return state.webViewApp;
    },
    getRedirectUrl(state) {
      return state.returnUrl;
    },
    getOS(state) {
      return state.os || getOS();
    },
  },
  mutations: {
    setOs(state) {
      state.os = getOS();
    },
    setWebView(state, payload) {
      state.webView = payload;
      //if (payload) router.push("/anmeldung");
    },
    setWebViewApp(state, payload) {
      state.webViewApp = payload;
      if (payload) router.push("/anmeldung");
    },
    SET_REDIRECT_URL(state, payload) {
      if (payload?.fullPath?.includes("pay")) state.returnUrl = payload;
    },
    // changeLang(state, payload) {
    //   app.$i18n.locale = payload
    //   setCurrentLanguage(payload);
    // }
  },
  actions: {
    // setLang({ commit }, payload) {
    //   commit('changeLang', payload)
    // }
  },
  modules: {
    User,
  },
});

function getOS() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("mac")) {
    return { platform: "macOS", excell: "csv" };
  } else if (userAgent.includes("win")) {
    return { platform: "Windows", excell: "xls" };
  } else {
    return { platform: "Windows", excell: "xls" };
  }
}
