import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Vuelidate from 'vuelidate'

import { BootstrapVue } from 'bootstrap-vue'
import VueYoutube from 'vue-youtube'
import store from './store/index'

import Scrollspy from 'vue2-scrollspy';
var VueScrollTo = require('vue-scrollto');

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)
Vue.component('calendar', Calendar)
Vue.component('v-date-picker', DatePicker)

Vue.config.productionTip = false

import "@/assets/scss/style.scss";
import "@/assets/css/materialdesignicons.min.css";

import excel from 'vue-excel-export'
 
Vue.use(excel)

Vue.use(VueYoutube)
Vue.use(Vuelidate)

Vue.use(Scrollspy);
Vue.use(VueScrollTo)

// Install BootstrapVue
Vue.use(BootstrapVue)


export default new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
