import { crypto } from "../../../utils/Crypto";

const apiKey = 'activeUser';

const setUser = (user) => {
    localStorage.setItem(apiKey, crypto(JSON.stringify(user)))
}

const getUser = () => {
    let data = localStorage.getItem(apiKey)
    return data;
}

const setUserToken = (token) => {
    localStorage.setItem('token', token)
}

const setLoginRoleType = (roleType) => {
    localStorage.setItem('roleType', roleType)
}

const getLoginRoleType = () => {
    let data = localStorage.getItem('roleType')
    return data;
}

const getUserToken = () => {
    let data = localStorage.getItem('token')
    return data;
}

const removeUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem(apiKey)
}

export const userOperation = {
    setUser,
    setUserToken,
    getUser,
    getUserToken,
    removeUser,
    setLoginRoleType,
    getLoginRoleType
};