export const en = {
  end: "Prepare",
  endText: "Your Income Tax Return in Turkish & Online with Flinktax",
  bannerText:
    "Getting your taxes done simply with Flinktax. With simple questions and no confusing tax jargon.",
  startButton: "Start now",
  howWorks: "How it works",
  taxReturn: "Tax return? With us!",
  createProfile: "Create a profile",
  createProfileText:
    "Register with just a few clicks and create your Flinktax account.",
  answerQuestion: "Upload documents",
  answerQuestionText: "Easily upload your documents",
  uploadDocuments: "Answer questions",
  uploadDocumentsText: "Answer our simple and understandable questions",
  relax: "Relax",
  relaxText: "We'll take care of the rest.",
  flinktaxCosts: "Flinktax costs",
  simpleCreateTax: "Simply create your tax return",
  fullService: "Full Service - With expert assistance",
  minimumTax: "Just 119.00 €",
  freeDocumentSystem:
    "With our free registration, easy document upload and free support, we minimise your hassle and ensure a straightforward tax return.",
  expertTeam:
    "Expert Team: Flinktax supports you with a team of experienced experts who provide you with professional assistance.",
  simpleTime:
    "Simple and time-saving process: Flinktax makes the tax return user-friendly. No complicated terms or difficult forms - everything is done simply and quickly.",
  maximumTax:
    "Maximum tax refund: Our experts help you benefit from legal tax advantages, thereby increasing the likelihood of a high tax refund for you.",
  startNow: "Start now",
  howExpertAssistance: "How expert assistance works",
  howExpertAssistanceText:
    "The easiest way to prepare a tax return. Register on the website and upload your documents. Our experts will review the uploaded files and prepare your tax return.",
  marriedSince: "Married since",
  clearSimpleQuestions: "Clear and simple questions",
  clearSimpleQuestionsText:
    "Register and answer simple questions within minutes! After you have answered the questions we ask, easily upload your documents.",
  simplyUploadDocument: "Simply upload documents.",
  simplyUploadDocumentText:
    "Forget the hassle of filling out paper forms and complicated data entry. Simply upload your relevant documents and we'll take care of the rest.",
  upload: "Upload",
  receivedDocument: "Received documents are being reviewed",
  dataValidation: "Data validation.",
  calculationTax: "Calculation of the tax refund / tax payment.",
  taxRefund: "Tax refund approved",
  expertsHelm: "Experts at the helm",
  expertsHelmText:
    "At Flinktax, you can rely on the expertise of our experienced tax experts. We offer professional support and ensure accurate and reliable tax returns.",
  confirmTax: "Confirm your tax return",
  confirmTaxText:
    "Our experts will provide you with a summary of your tax return. After you review and confirm it, it will be timely submitted to the tax authorities.",
  taxRefundExpert: "The estimated tax refund calculated by our experts",
  reviewConfirm: "Review and confirm",
  whyFlinktax: "Why Flinktax?",
  advantagesGlance: "Advantages at a glance",
  clarityTransparency: "Clarity and Transparency",
  clarityTransparencyText:
    "No tax jargon, just simple and clear instructions. With Flinktax you always know what to do and why.",
  safeReliable: "Safe and reliable processing.",
  safeReliableText: "At Flinktax, all data is transmitted encrypted via DATEV.",
  timeSaving: "Time-saving and efficient",
  timeSavingText:
    "With Flinktax, you don't have to spend hours searching through tax laws.",
  indivudal: "Individual customization - no mass production.",
  indivudalText:
    "Flinktax provides solutions specifically designed for your individual tax needs.",
  ihgp: "IHGP STEUER GmbH Steuerberatungsgesellschaft",
  costs: "Costs",
  about: "About",
  taxGuide: "Tax guide",
  faq: "FAQ",
  support: "Support",
  everything: "Everything else",
  contact: "Contact",
  career: "Career",
  agb: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  partnerShip: "Partnership",
  imprint: "Imprint",
  login: "Login",
  successAccountDeleteText: "Your account has been successfully deleted.",
  deleteAccountText:
    "Are you sure you want to delete your account? Please remember that this action is irrevocable.",
  successFrozenText: "Your account has been successfully frozen.",
  freezeAccountText: "Are you sure you want to freeze your account?",
  frozenText: "Want to freeze your account?",
  accountFrozen: "Freeze My Account",
  accountDeleteText: "Would you like to delete your account?",
  deleteMyAction: "Delete My Account",
  freezeMessage:
    "Your account appears to be frozen. Would you like to reactivate it?",
  yes: "Yes",
  no: "no",

  documentManagementSystem: "Flinktax",
  documentManagementSystemAdmin: "Admin Flinktax",
  loginFlinktax: "Register Flinktax",
  emailAddress: "E-mail address",
  password: "Password",
  register: "Register",
  welcomeText: "Welcome {name} ! What do you want to do?",
  documents: "Documents",
  bank: "Bank",
  tax: "Tax return",
  homePage: "Homepage",
  personalDetails: "Personal details",
  accountSettings: "Account settings",
  logout: "Log out",
  personalInfo: "Personal information",
  partnerInfo: "Partner Information",
  name: "First name",
  surname: "Last name",
  birth: "Date of birth",
  address: "Address",
  addressText: "Please enter your current",
  addressRegistration: "registration address",
  addressGermany: "in Germany",
  street: "Street",
  number: "Number",
  postCode: "POSTCODE",
  city: "City",
  iban: "IBAN",
  taxId: "Tax ID",
  save: "Save",
  language: "Language",
  deleteAccount: "Deactivate/delete account",
  back: "Back",
  next: "Next",
  account: "Account",

  withMinutes: "Within 3 minutes.",
  agreetext: "I agree to the",
  und: "and the",
  zu: "",
  alreadyAccount: "You already have an account?",
  registerNow: "Register now",
  confirmIdentity: "Confirm your identity",
  smsCodeText:
    "To ensure your account security, we send you an SMS code to make sure that only you have access to your account.",
  phoneNumber: "Mobile number",
  numberSsl: "Your number will be securely SSL encrypted.",
  enterSmsCode: "Enter SMS code",
  sentSmsCodeText:
    "Please enter the SMS code we sent you to the following mobile phone number",
  againSendSms: "Send SMS code again",
  selectYear: "Select a year to start.",
  questionText:
    "We will then ask you some basic questions to get a better understanding of your tax situation.",
  startTax: "Start your tax return now!",
  detailsMaritalStatus: "Details of your marital status",
  single: "Single",
  married: "Married / registered partnership",
  divorced: "Divorced",
  widowed: "Widowed",
  chooseDateMarriage: "Choose the date of your marriage",
  taxPower: "Sign tax power of attorney",
  taxPowerPartner: "Sign tax power of attorney (Partner)",
  taxPowerText:
    "I authorise Flinktax Tax Advisor with the following power of attorney to represent me in all tax matters.",
  showPreview: "Show preview",
  uploadTaxDocuments: "Please upload all tax relevant documents here",
  taxCertificate: "Income tax certificate",
  IncomeTaxCertificate: "Income tax certificate",
  incomeTaxCertificate: "Income tax certificate",
  investmentIncome: "Investment income",
  InvestmentIncome: "Investment income",
  advertisingCosts: "Professional expenses",
  AdvertisingCosts: "Professional expenses",
  MovingCosts: "Moving costs",
  movingCosts: "Moving costs",
  insurances: "Insuraces",
  Insurances: "Insuraces",
  fuses: "Insuraces",
  Fuses: "Insuraces",
  donations: "Donations & Contributions",
  Donations: "Donations & Contributions",
  calculationList: "Calculation List",
  CalculationList: "Calculation List",
  revenuesFromAbroad: "Foreign income",
  RevenuesFromAbroad: "Foreign income",
  childExpenses: "Information on your children",
  ChildExpenses: "Information on your children",
  taxDeclarations: "Tax Declarations",
  TaxDeclarations: "Tax Declarations",
  notice: "Tax Assessment",
  Notice: "Tax Assessment",
  other: "Other",
  Other: "Other",
  selectUploadDocument: "Select document for upload",
  maxiumumLimit: "Your maximum limit 50",
  confirm: "Confirm",
  thankYou: "Thank you very much!",
  relevantInformation: "We have received all the relevant information.",
  taxExpert:
    "Now it's our turn - our tax experts will evaluate your documents and notify you shortly.",
  documentOverview: "Back to Homepage",
  date: "Date",
  optional: "Optional",
  selectYear: "Select year",
  marriage: "Marriage Status",
  german: "German",
  turkish: "Turkish",
  english: "English",
  active: "Active",
  notActive: "Inactive",
  childrenQuestion: "Do you have children?",
  addChildren: "Add Child",
  workPlaceName: "Where do you work?",
  workPlaceQuestion: "Fill in if you worked in the tax year of your choice.",

  filingTaxReturn: "Filing your tax return",
  filingTaxReturnText:
    "Citizens have the possibility or are, if applicable obliged to file a tax return in Germany with the to the tax office.This serves to provide the tax office with all relevant information about income and expenses in order to calculate the tax burden correctly. calculate the tax burden correctly.In order to submit a correct and complete tax return and complete tax return, all relevant receipts and proofs should be collected be collected.This includes, among other things, wage and salary slips, bank statements, invoices for tradesmen's services and donation receipts.These receipts serve as proof of the information in the tax return and can be presented in the event of an audit by the tax office.",
  butTaxReturn: "But who is actually obliged to file a tax return?",
  simpleRules: "There are simple rules as to when",
  taxPayer: "a taxpayer",
  mustTaxReturn: "must file a tax return. You can find a ",
  taxReturnDoc: "list of items",
  and: "that are",
  mandatoryFile: "mandatory to file ",
  in: "in the",
  taxReturnSection: "tax return section.",
  additionalIncome: "If you earn an additional income of € 520 or more.",
  rentingAndLeasing: "The income from renting and leasing exceeds 520 €.",
  selfEmployment:
    "For income from self-employment. If you receive capital gains for which no capital gains tax has been paid, this is subject to withholding tax.",
  socialBenefits:
    "If you receive wage replacement benefits or social benefits (e.g. unemployment, insolvency, short-time work, maternity benefit, childcare allowance) above 520 €, you are subject to a progression rule.",
  contractsOverlap:
    "If two employment contracts overlap in time, resulting in two salaries being paid at the same time.",
  oldAgePension:
    "When the old-age pension is paid out, the so-called fifths method is used. If the spouse or registered partner chooses the tax combination III/V or the factor IV/IV.",
  educationAllowance:
    "In the case of a separated spouse, the education allowance, the disability allowance and the survivor's allowance are not divided equally. In addition, there are always special cases in which you have to file a tax return, which is not an easy decision for you. However, in such cases the tax office will usually contact you directly and inform you that you are obliged to file a tax return.",

  privacyPolicyPage: {
    mandatoryInformation: "General notice and mandatory information",
    responsibleBody: "Designation of the responsible body",
    dataControllerWebsite: "The data controller for this website is:",
    personalData:
      "The controller decides alone or jointly with others on the purposes and means of the processing of personal data (e.g. names, contact details or similar).",
    dataProcessing: "Revocation of your consent to data processing",
    dataProcessingText:
      "Some data processing operations are only possible with your express consent. You can revoke your consent at any time. An informal communication by e-mail is sufficient for the revocation. The legality of the data processing carried out until the revocation remains unaffected by the revocation.",
    dataSubjects: "Rights of the data subjects",
    dataSubjectsText:
      "Upon request, we will provide you with information free of charge about the status of the personal data collected about you and about the further processing of this data in accordance with the legal requirements (Art. 13 et seq. DSGVO). You have the right to rectification (Art. 16 DSGVO), restriction of processing (Art. 18 DSGVO), objection (Art. 21 DSGVO), data portability (Art. 20 DSGVO) as well as deletion of this data in part or in full in accordance with the legal requirements (Art. 17 DSGVO).",
    competentSupervisory:
      "Right to complain to the competent supervisory authority",
    competentSupervisoryText:
      "As a data subject, you have the right to lodge a complaint with the competent supervisory authority in the event of a breach of data protection law. The competent supervisory authority regarding data protection issues is the state data protection commissioner of the federal state in which our company is located. The following link provides a list of data protection officers and their contact details: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.",
    dataPortability: "Right to data portability",
    dataPortabilityText:
      "You have the right to have data that we process automatically on the basis of your consent or in fulfilment of a contract handed over to you or to third parties. The data will be provided in a machine-readable format. If you request the direct transfer of the data to another person responsible, this will only be done insofar as it is technically feasible.",
    correctionBlocking: "Right to information, correction, blocking, deletion",
    correctionBlockingText:
      "Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, the origin of the data, its recipients and the purpose of the data processing and, if applicable, a right to correction, blocking or deletion of this data. In this regard and also for further questions on the subject of personal data, you can contact us at any time via the contact options listed in the imprint.",
    ssl: "SSL or TLS encryption",
    sslText:
      'For security reasons and to protect the transmission of confidential content that you send to us as the site operator, our website uses SSL or TLS encryption. This means that data you transmit via this website cannot be read by third parties. You can recognise an encrypted connection by the "https://" address line of your browser and the lock symbol in the browser line.',
    dataCollection: "Data collection on our website",
    serverLogFiles: "Server log files",
    serverLogFilesText:
      "In server log files, the provider of the website automatically collects and stores information that your browser automatically transmits to us. These are:",
    browserType: "Browser type and version",
    operatingSystem: "Operating system used",
    referrerUrl: "Referrer URL",
    accessingComputer: "Host name of the accessing computer",
    serverRequest: "Time of the server request",
    ipAdress: "IP address",
    ipAdressText:
      "This data is not merged with other data sources. The data processing is based on Art. 6 para. 1 lit. b DSGVO, which permits the processing of data for the fulfilment of a contract or pre-contractual measures.",
    cookies: "Cookies",
    cookiesWebsiteText:
      "Our website uses cookies. These are small text files that make it possible to store specific information related to the user on the user's terminal device while the user is using the website. Cookies make it possible, in particular, to determine the frequency of use and number of users of the pages, to analyse behaviour patterns of page use, but also to make our offer more customer-friendly. Cookies remain stored beyond the end of a browser session and can be retrieved when you visit the site again. If you do not wish this, you should set your internet browser to refuse the acceptance of cookies.",
    cookiesUseText:
      "The legal basis for the use of cookies is Art. 6 para. 1 p. 1 lit.f DSGVO.",
    cookiesUseWebsiteText:
      "We use cookies for the proper operation of the website, to provide basic functionalities and to tailor our websites to preferred areas of interest. This is also the legitimate interest in data processing within the meaning of Art. 6 (1) sentence 1 lit. f DSGVO.",
    requestEmailTel: "Request by e-mail, phone or fax",
    requestEmailTelDataText:
      "If you contact us by e-mail, telephone or fax, your enquiry including all resulting personal data (name, enquiry) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.",
    requestEmailTelPerfText:
      "The processing of this data is based on Art. 6 (1) lit. b DSGVO if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the enquiries addressed to us (Art. 6 para. 1 lit. f DSGVO) or on your consent (Art. 6 para. 1 lit. a DSGVO) if this has been requested.",
    contactForm: "Contact form",

    contactFormDetailText:
      "Data transmitted via the contact form, including your contact details, are stored in order to process your enquiry or to be available for follow-up questions. This data will not be passed on without your consent",
    contactFormProcessText:
      "The data entered in the contact form is processed exclusively on the basis of your consent (Art. 6 para. 1 lit. a DSGVO). You may revoke your consent at any time. An informal communication by e-mail is sufficient for the revocation. The legality of the data processing operations carried out until the revocation remains unaffected by the revocation.",
    contactFormProcessText:
      "Data transmitted via the contact form will remain with us until you request us to delete it, revoke your consent to store it or there is no longer any need to store it. Mandatory legal provisions - in particular retention periods - remain unaffected.",
    socialMediaPlugins: "social media plugins",
    youtube: "YouTube",
    youtubeOneText:
      "For integration and display of video content, our website uses plugins from YouTube. The provider of the video portal is YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA.",
    youtubeTwoText:
      "When a page with an integrated YouTube plugin is called up, a connection to YouTube's servers is established. YouTube thereby learns which of our pages you have accessed.",
    youtubeThreeText:
      "YouTube can assign your surfing behaviour directly to your personal profile if you are logged into your YouTube account. By logging out beforehand, you have the option to prevent this.",
    youtubeFourText:
      "YouTube is used in the interest of an appealing presentation of our online offers. This constitutes a legitimate interest within the meaning of Art. 6 para. 1 lit. f DSGVO.",
    youtubeFiveText:
      "Details on the handling of user data can be found in YouTube's privacy policy at: https://www.google.de/intl/de/policies/privacy.",
    xing: "Xing",
    xingOneText:
      "Our website uses functions of the XING network. The provider is XING AG, Dammtorstraße 29-32, 20354 Hamburg, Germany",
    xingTwoText:
      "When a page with integrated XING functions is called up, a connection to the XING servers is established. As far as we know, no personal data is stored in this process. IP addresses are not stored, nor is usage behaviour evaluated.",
    xingThreeText:
      "Details on data protection and the XING Share button can be found in XING's privacy policy at: https://www.xing.com/app/share?op=data_protection.",
    twitter: "Twitter",
    twitterOneText:
      "Our website uses functions of the Twitter service. The provider is Twitter Inc, 1355 Market Street, Suite 900, San Francisco, CA 94103, USA",
    twitterTwoText:
      "When using Twitter and the 'Re-Tweet' function, websites you visit are linked to your Twitter account and published in your Twitter feed. This involves the transmission of data to Twitter. We have no knowledge of the content of the transmitted data or the use of this data by Twitter. Details can be found in Twitter's privacy policy: https://twitter.com/privacy.",
    twitterThreeText:
      "You can change your privacy settings on Twitter: https://twitter.com/account/settings.",
    linkedin: "LinkedIn",
    linkedinOneText:
      "Our website uses functions of the LinkedIn network. The provider is LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA",
    linkedinTwoText:
      'Each time one of our pages containing functions of LinkedIn is accessed, a connection to LinkedIn servers is established. LinkedIn is informed that you have visited our web pages with your IP address. If you click the LinkedIn "Recommend" button and are logged into your LinkedIn account, it is possible for LinkedIn to associate your visit to our website with you and your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by LinkedIn.',
    linkedinThreeText:
      "For more information, please see LinkedIn's privacy policy at: https://www.linkedin.com/legal/privacy-policy.",
    facebook: "Facebook",
    facebookOneText:
      'Plugins of the social network Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA are integrated on our pages. You can recognise the Facebook plugins by the Facebook logo or the "Like button" ("Like") on our page.',
    facebookTwoText: "You can find an overview of the Facebook plugins here:",
    facebookThreeText: "http://developers.facebook.com/docs/plugins/",
    facebookFourText:
      "When you visit our pages, a direct connection is established between your browser and the Facebook server via the plugin. Facebook thereby receives the information that you have visited our site with your IP address. If you click the Facebook 'Like' button while you are logged into your Facebook account, you can link the content of our pages on your Facebook profile. This allows Facebook to associate the visit to our pages with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the transmitted data or its use by Facebook. Further information on this can be found in facebook's privacy policy at https://de-de.facebook.com/policy.php",
    facebookFiveText:
      "If you do not want Facebook to be able to associate your visit to our pages with your Facebook user account, please log out of your Facebook account.",
    linksWebsite: "Links to websites of other providers",
    linksWebsiteText:
      "Our websites may contain links to websites of other providers to which this data protection declaration does not extend. Insofar as the collection, processing or use of personal data is associated with the use of the websites of other providers, please observe the data protection notices of the respective providers.",
    analysisTools: "Analysis Tools and Advertising",
    googleAnalytics: "Google Analytics",
    analysisToolsOneText:
      "Our website uses functions of the web analysis service Google Analytics. The provider of the web analytics service is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.",
    analysisToolsTwoText:
      'Google Analytics uses "cookies." These are small text files that your web browser stores on your terminal device and enable an analysis of website usage. Information generated by cookies about your use of our website is transmitted to a Google server and stored there. The server is usually located in the USA.',
    analysisToolsThreeText:
      "The setting of Google Analytics cookies is based on Art. 6 (1) lit. f DSGVO. As the operator of this website, we have a legitimate interest in analysing user behaviour in order to optimise our website and, if necessary, also advertising.",
    ipAnonymisation: "IP anonymisation",
    ipAnonymisationOneText:
      "We use Google Analytics in conjunction with the IP anonymisation function. It ensures that Google truncates your IP address within member states of the European Union or in other states party to the Agreement on the European Economic Area before transmitting it to the USA. There may be exceptional cases where Google transmits the full IP address to a server in the USA and truncates it there. Google will use this information on our behalf for the purpose of evaluating your use of the website, compiling reports on website activity and providing us with other services relating to website activity and internet usage. There is no aggregation of the 'data' transmitted by Google Analytics",
    ipAnonymisationTwoText: "IP address with other data from Google.",
    browserPlugin: "Browser Plugin",
    browserPluginText:
      "The setting of cookies by your web browser can be prevented. However, some functions of our website may be restricted as a result. You can also prevent the collection of data relating to your use of the website, including your IP address and subsequent processing by Google. This is possible by downloading and installing the browser plugin accessible via the following link: https://tools.google.com/dlpage/gaoptout?hl=de.",
    objectionCollectionData: "Objection to data collection",
    objectionCollectionDataOneText:
      "You can prevent the collection of your data by Google Analytics by clicking on the following link. An opt-out cookie will be set to prevent the collection of your data during future visits to our website: 'Disable Google Analytics.'",
    objectionCollectionDataTwoText:
      "For details on how Google Analytics handles user data, please see Google's privacy policy: https://support.google.com/analytics/answer/6004245?hl=de.",
    jobProcessing: "Order processing",
    jobProcessingText:
      "In order to fully comply with the legal data protection requirements, we have entered into an order processing agreement with Google",
    orderProcessing:
      "In order to fully comply with the legal data protection requirements, we have concluded an order processing contract with Google",
    orderProcessingText:
      'Our website uses the "demographic characteristics" feature of Google Analytics. It can be used to create reports that contain statements about the age, gender and interests of page visitors. This data comes from interest-based advertising from Google as well as visitor data from third-party providers. It is not possible to assign the data to a specific person. You can deactivate this function at any time. This is possible via the ad settings in your Google account or by generally prohibiting the collection of your data by Google Analytics, as explained in the point "Objection to data collection".',
    demographicCharacteristics:
      "Demographic characteristics on Google Analytics",
    demographicCharacteristicsText:
      'Our website uses the "demographic characteristics" feature of Google Analytics. It can be used to create reports that contain statements about the age, gender and interests of site visitors. This data comes from interest-based advertising from Google as well as visitor data from third-party providers. It is not possible to assign the data to a specific person. You can deactivate this function at any time. This is possible via the ad settings in your Google account or by generally prohibiting the collection of your data by Google Analytics, as explained in the item "Objection to data collection".',
    newsletter: "Newsletter",
    newsletterData: "Newsletter data",
    newsletterOneText:
      "If you would like to receive the newsletter offered on the website, we require an email address from you as well as information that allows us to verify that you are the owner of the email address provided and that you agree to receive the newsletter. Further data will not be collected or will only be collected on a voluntary basis. We use this data exclusively for sending the requested information and do not pass it on to third parties.",
    newsletterTwoText:
      'The processing of the data entered in the newsletter registration form is based exclusively on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke your consent to the storage of the data, the e-mail address and their use for sending the newsletter at any time, for example via the "unsubscribe" link in the newsletter. The legality of the data processing operations already carried out remains unaffected by the revocation. The data you provide us with for the purpose of receiving the newsletter will be stored by us or the newsletter service provider until you unsubscribe from the newsletter and will be deleted from the newsletter distribution list after you unsubscribe from the newsletter. Data that has been stored by us for other purposes remains unaffected by this. After you have unsubscribed from the newsletter distribution list, your e-mail address will be stored by us or the newsletter service provider in a blacklist, if necessary, in order to prevent future mailings. The data from the blacklist will only be used for this purpose and will not be merged with other data. This serves both your interest and our interest in complying with the legal requirements when sending newsletters (legitimate interest within the meaning of Art. 6 Para. 1 lit. f DSGVO). The storage in the blacklist is not limited in time. You can object to the storage if your interests outweigh our legitimate interest.',
    pluginsAndTools: "Plugins and Tools",
    facebookPixel: "Facebook Pixel",
    pluginsAndToolsText:
      'We use the visitor action pixel from Facebook Inc, 1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook"). By calling up this pixel from your browser, Facebook can subsequently recognise whether a Facebook ad was successful. We only receive statistical data from Facebook for this purpose without any reference to a specific person. This allows us to record the effectiveness of the Facebook ads for statistical and market research purposes. In particular, if you are logged in to Facebook, we refer you to their data protection information https://www.facebook.com/about/privacy/. Please go to www.facebook.com/settings?tab=ads if you wish to withdraw your consent to the visitor action pixel. The legal basis for the use of the Facebook pixel is Art. 6 para. 1 p. 1 lit. f DSGVO.',
    instagram: "Instagram",
    instagramText:
      "As part of our online offer, functions and content of the service Instagram, offered by Instagram Inc, 1601 Willow Road, Menlo Park, CA, 94025, USA, may be integrated. This may include, for example, content such as images, videos or texts and buttons with which users can make known their liking of the content, the authors of the content or subscribe to our posts. If the users are members of the Instagram platform, Instagram can assign the call-up of the above-mentioned content and functions to the user's profile there. Instagram privacy policy: http://instagram.com/about/legal/privacy/.",
    googleMaps: "Integration of Google Maps",
    googleMapsOneText:
      "We use the Google Maps service on this website. The legal basis for the use is Art. 6 para. 1 p. 1 lit. f DSGVO.",
    googleMapsTwoText:
      "By visiting the website, Google receives the information that you have called up the corresponding sub-page of our website. We have no influence on the data collected and data processing operations, nor are we aware of the full extent of the data collection, the purposes of the processing, the storage periods. The data processing takes place regardless of whether Google provides a user account via which you are logged in or whether no user account exists. If you are logged in to Google, your data will be directly assigned to your account. If you do not want your data to be associated with your Google profile, you must log out before activating the button. Google stores your data as usage profiles and uses them for the purposes of advertising, market research and/or the needs-based design of its website. Such an evaluation is carried out in particular (even for users who are not logged in) to provide needs-based advertising and to inform other users of the social network about your activities on our website. You have a right to object to the creation of these user profiles, and you must contact Google to exercise this right.",
    googleMapsThreeText:
      "For more information on the purpose and scope of data collection and processing by the plug-in provider, please refer to the provider's privacy policy. There you will also find further information on your rights in this regard and setting options for protecting your privacy: http://www.google.de/intl/de/policies/privacy. Google also processes your personal data in the USA and has submitted to the EU-US Privacy Shield, https://www.privacyshield.gov/EU-US-Framework.",
    privacyPolicyChange: "Changes to the privacy policy",
    privacyPolicyChangeText:
      "We reserve the right to change this privacy policy at any time in accordance with applicable data protection legislation. The current status is July 2020.",
    rightDataSubjects: "Data subjects' rights",
    rightDataSubjectsText:
      "Upon request, we will provide you with information free of charge about the status of the personal data collected about you and about the further processing of this data in accordance with the legal requirements (Art. 13 et seq. DSGVO). You have the right to rectification, restriction of processing, objection, data portability as well as deletion of this data in part or in full in accordance with the legal requirements. You can reach us at",
  },
  impressumPage: {
    imprint: "Imprint",
    companyInformation:
      "Company information according to § 5 Telemediengesetz (TMG) and §§ 2,3 Dienstleistungs-Informationspflichten-Verordnung (DL-InfoV)",
    responsibleContent:
      "Responsible for the content according to § 55 para. 2 RStV:",
    reinerHornberger: "Rechtsanwalt Reiner Hornberger",
    businessResident: "Business Resident:",
    germany: "Germany",
    serviceTimes: "service times:",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    datesArrangement: "Appointments by arrangement",
    thursday: "Thursday",
    friday: "Friday",
    outsideServiceHours:
      "Appointments by arrangement, also outside service hours",
    internetPresence:
      "This website represents the internet presence of the following company:",
    internetPresenceOneText:
      "IHGP STEUER GmbH Steuerberatungsgesellschaft is a limited liability company (GmbHG) with its registered office in Berlin.",
    internetPresenceTwoText:
      "Register Court: Berlin (Charlottenburg) District Court, Register Number: HRB 113132 B",
    internetPresenceThreeText:
      "VAT identification number according to § 27a UStG: DE 259297106",
    internetPresenceFourText: "Managing Director: Tax Consultant Sinan Ilhan.",
    internetPresenceFiveText:
      "Mr Sinan Ilhan is a tax consultant. The professional title has been awarded in the Federal Republic of Germany.",
    competentChamber: "Competent chamber:",
    competentChamberText:
      "The company named in this imprint is licensed as a tax consultancy in the Federal Republic of Germany and accordingly belongs to the competent Chamber of Tax Consultants in Berlin:",
    publicLaw: "Corporation under public law",
    berlinTax: "Chamber of Tax Consultants Berlin",
    phone: "Telephone",
    fax: "Telefax",
    taxConsultant:
      "Berufsrechtliche Regelungen Steuerberater und Steuerberatungsgesellschaften:",
    taxConsultanty: "Steuerberatungsgesetz (StBerG)",
    implementingTaxConsultanty:
      "Implementing Regulation to the Tax Consultancy Act (DVStB)",
    codeTaxConsultanty: "Berufsordnung der Bundes-Steuerberaterkammer (BOStB)",
    ordinanceTaxConsultanty:
      "Ordinance on the Remuneration of Tax Consultants (StBVV)",
    homePageLink:
      "The above regulations are available on the homepage of the German Federal Chamber of Tax Advisors at www.bstbk.de",
    disclaimer: "Disclaimer",
    liabilityContents: "Liability for contents",
    liabilityContentsText:
      "As a service provider, we are responsible for our own content on these pages in accordance with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG). According to §§ 8 to 10 TMG, however, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this respect is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of any such infringements, we will remove the relevant content immediately.",
    liabilityForLinks: "Liability for links",
    liabilityForLinksOneText:
      "Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking.",
    liabilityForLinksTwoText:
      "However, permanent monitoring of the content of the linked pages is not reasonable without concrete evidence of a legal violation. If we become aware of any infringements, we will remove such links immediately.",
    copyright: "Copyright",
    copyrightText:
      "The contents and works on these pages created by the site operators are subject to German copyright law. The reproduction, editing, distribution and any kind of exploitation outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.",
    euText:
      "The EU Commission has created an Internet platform for online dispute resolution. This platform serves as a contact point for the out-of-court settlement of disputes concerning contractual obligations arising from online purchase contracts. More information is available at the following link: http://ec.europa.eu/consumers/odr. Flinktax is neither obliged nor willing to participate in a dispute resolution procedure before a consumer arbitration board.",
  },
  faqPage: {
    faqAbout: "FAQ on Flinktax",
    whatFlinktax: "What is Flinktax?",
    flinktaxModernPlatform:
      "Flinktax is a modern platform that allows you to do your tax return easily, conveniently and online",
    readMore: "Read more",
    whatFlinktaxText:
      "We offer you a user-friendly environment where you can easily upload your tax documents, enter important information and follow the entire process from preparing to filing your tax return. Our platform makes the tax process easier and saves you time, while our experienced experts ensure that your tax return is processed correctly and on time.",
    flinktaxSafe: "Is Flinktax safe?",
    safetyFlinktax:
      "Our servers are all located in Germany to ensure the highest security standards.",
    safetyFlinktaxOneText:
      "We only use your personal information to submit your tax return digitally to the tax office. We store your data in accordance with legal requirements and are obliged to keep it for five years in order to comply with any queries from the tax office. This storage also has another advantage: thanks to the data transfer next year, your tax return will be even quicker and more convenient with Flinktax!",
    safetyFlinktaxTwoText:
      "We strictly adhere to the provisions of the European General Data Protection Regulation (GDPR) to ensure your privacy. All your data transfers are made over encrypted HTTPS/SSL connections to ensure that your information remains confidential.",
    safetyFlinktaxThreeText:
      "Our servers are all located in Germany to ensure the highest security standards",
    safetyFlinktaxFourText:
      "We only use your email address to provide you with valuable tips and information about your tax return. We will also inform you about the introduction of new functions",
    safetyFlinktaxFiveText:
      "You can be sure that we will not share any information you provide in the chat related to your tax return with tracking services or advertisers. All data collected is used only to improve our product and marketing efforts and to generate anonymous statistical values. Your privacy remains protected with us at all times.",
    flinktaxSupport: "Expats: How does Flinktax support you?",
    uniqueTaxChallenges:
      "If you're an expatriate living and working in Germany, we understand the unique tax challenges you may face.",
    flinktaxSupportOneText:
      "Flinktax specialises in helping expats with their tax affairs. Our experienced tax experts are well versed in the complex tax aspects of expatriates and can ensure that your tax return is processed correctly and efficiently.",
    flinktaxSupportTwoText:
      "Our user-friendly platform makes it easy for you to enter all the relevant information, and our experts will review your details to ensure that all the necessary steps are taken to meet your tax obligations while maximising your refund.",
    flinktaxSupportThreeText:
      "At Flinktax, we know that expats have specific needs when it comes to tax issues. That's why we offer bespoke solutions tailored to your individual circumstances. We're here to help you make sure you get the most out of your tax situation and focus on making the most of your stay in Germany.",
    foreignIncome: "How is foreign income taxed in Germany?",
    progressionProviso:
      "Income that you have earned abroad during your stay is subject to the so-called progression proviso in Germany.",
    progressionProvisoText:
      "This means that it is included in the calculation of the tax rate that is applied to your actual taxable income. As a result, this foreign income increases the tax rate on your domestic income. Income earned during your stay in Germany is generally subject to full tax liability.",
    relocationExpenses:
      "As an expatriate, can I deduct my relocation expenses related to my job from my tax return?",
    relocationExpensesDesc:
      "If your change of residence is undoubtedly due to professional reasons, you can include the costs associated with the move as so-called income-related expenses in your tax return.",
    relocationExpensesText:
      "This can be the case, for example, if you changed your place of residence because of a new job and the move was necessary in order to be able to carry out your professional activity. Such work-related moving expenses may include expenses such as transport costs, estate agent fees, rent deposits or double rent payments. It is important to note that there are certain conditions that must be met in order for these costs to be tax deductible. As a rule, the costs must be related to your professional activity and must be verifiably incurred.",
    germanTaxReturn:
      "Why do I have to declare my income from abroad in my German tax return?",
    germanTaxReturnDesc:
      "If you are resident in Germany, foreign income is generally subject to taxation in Germany.",
    germanTaxReturnText:
      "This applies regardless of whether or not there is a double taxation agreement (DTA) with the foreign country in question. As a rule, therefore, this income is taxed in Germany, irrespective of possible international agreements.",
    howFlinktaxWorks: "How does Flinktax work? ",
    howFlinktaxWorksDesc:
      "You register on our platform, answer our simple questions, upload the required documents and we take care of the rest.",
    howFlinktaxWorksText:
      "Flinktax offers you a simple and convenient way to do your tax return online. The process is structured in several steps to ensure that you enter all the necessary information correctly and that no important details are overlooked.",
    howFlinktaxWorksText:
      "Flinktax offers you a simple and convenient way to complete your tax return online",
    registration: "Registration:",
    registrationText:
      "Log in to our platform and create your personal user account. Enter the required information such as name, email address and password.",
    profiling: "Profile creation:",
    profilingText:
      "After registration, our onboarding process will guide you through the profile creation. Provide additional information such as personal details, tax identification number and relevant tax information.",
    answerQuestion: "Answer questions:",
    answerQuestionText:
      "Start working on your tax return by answering our easy-to-understand questions. Flinktax will guide you through the process and ensure all relevant information is captured.",
    documentUpload: "document upload:",
    documentUploadText:
      "Upload the documents required for your tax return. Our platform allows you to store your documents securely and digitally.",
    professionalReview: "Professional review:",
    professionalReviewText:
      "Our experienced tax experts will review your details and documents to ensure everything is correct and complete.",
    editingSubmission: "Editing and submission:",
    editingSubmissionText:
      "After the review, your tax return will be processed and submitted to the tax office via the official ELSTER interface. You can follow the progress of your tax return in real time.",
    refund: "Refund:",
    refundText:
      "As soon as your tax return is processed and a refund is due, the amount will be transferred to the account you specified",
    support: "Support:",
    supportText:
      "Our customer support is available throughout the process to answer your questions and assist you",
    flinktaxOffers:
      "Flinktax offers a seamless, efficient and reliable solution to complete your tax return stress-free. You retain control of the process and can rest assured that your tax affairs are in capable hands",
    helpQuestion: "Will I get help with questions?",
    helpQuestionText:
      "Of course! Our customer support team is here to answer all your questions and help you through every step of the process.",
    customerSupportTeam:
      "Our customer support team is available to answer all your questions and assist you throughout the process",
    directChatSupport: "Direct chat support:",
    directChatSupportText:
      "We've included a handy chat on our platform that allows you to ask your questions directly to us. Whether it's about specific tax details, process flow or general enquiries, our team is here to help. You'll get timely answers that help you understand more clearly what you need to do.",
    faqResources: "FAQ and Resources:",
    faqResourcesOneText:
      "In addition to our direct chat support, we offer a comprehensive collection of frequently asked questions (FAQs) and resources that provide you with useful information and guidance. These can help you find answers to common questions and steer you in the right direction",
    faqResourcesTwoText:
      "Our goal is to make sure you go through the tax filing process without confusion or uncertainty. Don't hesitate to use chat support or browse our resources to get the answers you need. We're here to help you get the most out of our platform and guide you every step of the way.",
    myTaxRefund: "How do I get my tax refund?",
    myTaxRefundText:
      " Once your tax return is processed, your refund will be transferred to the account you specified.",
    myTaxRefundOneText:
      "In most cases you can expect a refund of overpaid taxes. Once your tax return has been processed by Flinktax and sent to the tax office via the official ELSTER interface, the process begins. The tax office checks your information and calculates the amount you will get back. This amount is then transferred to the bank account you specified.",
    myTaxRefundTwoText:
      "Please note that the exact time for the refund may vary",
    trackProcessing: "Can I track the processing status of my tax return?",
    trackProcessingText:
      " Yes, we offer a function that allows you to track the progress of your tax return.",
    trackProcessingOneText:
      "It is very important to us that you can keep track of the progress of your tax return at all times. We understand how important it is to know what stage your tax return is at. That's why we offer you a convenient way to track the processing status of your tax return in real time",
    trackProcessingTwoText:
      "You can view the current status of your tax return directly on your Flinktax account. The following steps and items are displayed during the processing process:",
    trackProcessingOneItem: "Received documents will be checked",
    trackProcessingTwoItem: "Data validation",
    trackProcessingThreeItem: "Calculation of tax refund/back payment",
    trackProcessingFourItem: "Transmission of tax return",
    trackProcessingFiveItem: "Tax refund approved",
    transparentProcessingSteps:
      "We understand you're curious about how far your tax return has progressed. With our transparent processing steps, you can check the status at any time and be sure your tax return is on track.",
    costUseFlinktax: "How much does it cost to use Flinktax?",
    costUseFlinktaxText:
      " At Flinktax, we have transparent and fair pricing based on your tax refund.",
    costUseFlinktaxOneText:
      " Our fee is 20% of your tax refund, but at least 119 euros.",
    smartPhoneFlinktax: "Can I use Flinktax on my smartphone?",
    smartPhoneFlinktaxText:
      " Yes, you can use our platform on your smartphone or tablet as it is optimised for mobile devices.",
    smartPhoneFlinktaxOneText:
      "We understand the importance of flexibility and convenience. That's why you can use Flinktax not only on your computer, but also on your smartphone. Our website is optimised for mobile devices, so you can easily complete your tax return on the go.",
    smartPhoneFlinktaxTwoText:
      "In addition, we are currently working on our own app, which will be available soon. The Flinktax app will make it even easier and more convenient to prepare and manage your tax return. You can upload documents, answer questions and track the progress of your tax return anytime, anywhere, right from your smartphone.",
  },
  supportText: "Do you need support? We are always at your disposal at ",
  supportContactText: "",
  supportHelp: "Support that really helps",
  supportContact: "Contact support",
  aboutPage: {
    about: "About",
    taxTerminology:
      "Tired of slogging through endless forms and getting lost in tax terminology?",
    aboutOneText:
      "Our goal is to make the tax return process as efficient and stress-free as possible. Why? Because we believe that everyone has the right to do taxes without headaches.",
    aboutTwoText:
      "Our mission is clear - we want to penetrate the tax return market with a breath of fresh air. In doing so, we put a special focus on the needs of expats from Turkey. We understand how important it is to do your tax return in your own mother tongue. That's why we offer a platform that makes it easy to do your tax return in Turkish. Because we know that language is the key to comprehensibility.",
    taxReturnMatter: "Tax returns are a matter for experts:",
    taxReturnMatterOneText:
      "We leave your tax return exclusively to our experts. You can sit back and trust our professionals to handle the process for you. Your tax affairs are in the best hands.",
    taxReturnMatterTwoText:
      "Simplicity, transparency and trust: We put all our motivation and energy into providing just that. Your satisfaction is what drives us, and we work tirelessly to provide you with the best possible experience.",
    taxButton: "Have your tax return done now",
  },
  validateMessages: {
    pleaseName: "Please enter your name",
    pleaseSurname: "Please enter your surname",
    pleaseBirthDate: "Please enter your birthday",
    pleaseStreet: "Please enter the street name",
    pleaseNumber: "Please enter apartment no",
    pleasePostalCode: "Please enter postcode",
    pleaseCity: "Please enter your city",
    pleaseEmail: "Please enter your email address",
    pleaseAccount: "Please select your account status",
    pleaseEmailFormat: "Not in Email format",
    pleasePassword: "Please enter your password",
    pleasePhoneNo: "Please enter your telephone number",
    errorEmailPassword: "You entered your e-mail or password incorrectly.",
    updateProfile: "The profile has been updated.",
    notUpdateProfile: "The profile could not be updated.",
    downloadDoc: "Files have been uploaded.",
    notDownloadDoc: "Files could not be uploaded.",
    updateAccount: "Account settings have been updated.",
    notUpdateAccount: "Account settings could not be updated.",
    pleaseYear: "Please select your anniversary",
    emailMatch: "There are accounts that match this email.",
    phoneMatch: "There are accounts matching this phone number.",
    ibanMaxLength: "Must not be longer than 22 characters.",
    ibanMinLength: "Must not be shorter than 22 characters.",
    idMinLength: "Must be longer than 11 characters.",
    newPassword: "The new password cannot be blank.",
    againNewPassword: "Enter your password again.",
    newPasswordValidate: "No two passwords are the same.",
    phoneNumberLength: "The number of characters is not equal.",
    minPostalCodeText: "Must be less than 5 characters.",
    maxPostalCodeText: "Must be more than 5 characters.",
    documentRequired:
      "At least one file must be attached to one of the fields.",
    phoneCodeMatch: "The entered telephone number is not valid.",
    ibanRequired: "Please enter your iban number.",
    passwordFormat:
      "Your password must contain uppercase, lowercase letters, numbers and signs.",
    pleaseCompanyName: "Please enter company name.",
  },
  hi: "Hi",
  termsConditions: {
    oneTitle: "General Terms and Conditions (GTC) for the use of the",
    twoTitle: "Flinktax platform",
    threeTitle: "Terms of use of the flinktax.de website",
    fourTitle: "Last update:",
    fiveTitle: "Preamble",
    termsOneText:
      "Welcome to Flinktax! We are proud to offer an innovative platform that makes it easy for you to get your income tax returns done.",
    termsTwoText:
      "Our user-friendly technology allows you to complete your tax affairs efficiently and conveniently. We understand how important it is to meet your financial obligations correctly and in a timely manner, and we are here to help you do that. With our platform, you can prepare your income tax returns quickly and accurately to ensure you take advantage of all possible tax benefits. We look forward to helping you with your tax needs and providing you with a stress-free experience. Here, the services are provided by independent partners of the Flinktax platform.",
    conditionsTitle: "Acceptance of the conditions",
    conditionsText:
      "By using our income tax return preparation platform, you unconditionally agree to these terms and conditions. If you do not agree to these terms, you may not use the services of our platform. These terms and conditions apply to the use of the platform provided by Flinktax to find third party providers for the provision of tax advice to individuals, the engagement of platform partners by the user and the use of software solutions provided by Flinktax.",
    scopeServicesTitle: "Scope of Services",
    scopeServicesOneText:
      "Through our web service, users are enabled to prepare, organise and file the necessary information and documents for their tax return.",
    scopeServicesTwoText:
      "Through the website, relevant data is provided by the user that is required to prepare his tax return for submission to the tax authorities. A user's personal data includes: Name, marital status, employment, address, religious affiliation, occupation, employer,income tax certificate, second home, competent tax office, tax identification number, education and training, expenditure on work equipment, job applications, professional associations, income from capital assets (if a bank receipt is available), income from the sale of goods (with the exception of income from pension funds), insurance, health expenses, survivors' contributions, disabilities, carers, personal details of children living in the same household, parents or other persons, donations, church tax, household expenses, expenses from pension funds, permanent expenses, maintenance payments to third parties and losses carried forward, where applicable. The third party provider uses the data entered by the user to calculate the user's tax burden and the refund amount (if any).",
    scopeServicesThreeText:
      "The service contract is concluded after calculation of a user's tax burden and refund amount, if the user chooses to do so. Upon conclusion of the service contract, the tax return is sent to the user's responsible tax office for submission via ELSTER, the digital portal of the German tax authorities.",
    scopeServicesFourText:
      "The verification of the respective user data is done with the help of the following documents: wage tax certificate, wage/salary statement, registration confirmation, German identity card or other identification documents. showing the address. If we need additional information to verify your identity or file your tax return, we will inform you and ask for your consent to provide this information",
    scopeServicesFiveText:
      "After a user's tax return is completed and filed, we will provide the user with a copy of the filed tax return for download from our document management system or by email. We will send the user an email to confirm the submission.",
    scopeServicesSixText:
      "We do not warrant or make any representation or guarantee that the tax charge, refund or back payment calculated by our partner law firm is accurate.However, if there are any discrepancies with the final assessment by the tax authorities, we encourage our users to contact us at mail@flinktax.de (create extra email) and let us know so that we can improve our services",
    scopeServicesSevenText:
      "Our services do not fall under the services subject to authorisation under the Tax Advisory Act (StBerG) or the Legal Services Act (RDG). If you have specific tax or legal questions, we recommend that you seek advice from a tax or legal advisor,",
    serviceContractTitle: "Service contract",
    serviceContractOneText:
      "To use Flinktax you will need a user account, which you will receive when you register on Flinktax. To register, you will need to provide an email address and set a password. The user account is non-transferable",
    serviceContractTwoText:
      "The following special requirement exists for the transmission of the tax return prepared by our partner law firms to the tax authorities:",
    serviceContractThreeText:
      'You are only entitled to file a tax return with Flinktax if you have not already commissioned a member of the tax advisory profession (tax advisor, auditor, lawyer) to process a corresponding tax return for the assessment period in question (hereinafter referred to as "usage requirement"). The usage requirement is intended to avoid duplicate transmissions of the tax return to the tax authorities. In order to determine whether you are entitled to submit the tax return via Flinktax, you will be asked when using Flinktax whether this usage requirement is met ("self-declaration").',
    serviceContractFourText:
      "Our services include both web and mobile applications and related services. Through these applications, our users can conveniently prepare, file and submit their tax returns via the app. In addition, we offer advanced services in financial matters and support around their tax returns.",
    serviceContractFiveText:
      "Through Flinktax, users have the option to get a free initial assessment from platform partners for their chosen assessment periods based on the e-data filed with the tax authorities. Please note that neither the conclusion of a service contract with a platform partner nor the performance of a free initial assessment constitutes a legal claim. It may happen that no platform partner is available and therefore the initial assessment cannot be carried out.",
    serviceContractSixText:
      "The platform partners provide their services independently and carry out the billing for their services themselves. Flinktax merely acts as a technical service provider and provides support in this function",
    serviceContractSevenText:
      "There is no obligation to conclude a contract on the part of the users for the acceptance of the offer or service contract",
    serviceContractEightText:
      "After a user has accepted an offer, they will receive an email confirmation that the service has been contracted. It is important to note that the service contract is solely between the user and the platform partner. Flinktax assumes no responsibility for the conclusion of the service contract, its content or execution. In particular, Flinktax is not liable for the services provided by the platform partners and is not liable for any warranty or compensation.",
    partyServicesTitle: "Third-party services",
    partyServicesOneText:
      "Selected third party service providers are offered the opportunity to provide certain products and services through our website. If you accept such an offer from a third party provider, the relevant contract will be exclusively between you and the third party provider. The third party provider is solely responsible for the performance of the service offered. We recommend that you read the privacy statements and terms of use of these third-party providers thoroughly.",
    partyServicesTwoText:
      "We do not accept any responsibility or liability for the actions, products and content of third party providers or any third parties or the quality, security or legality of the services offered by third party providers. The provisions of § 6 (in the General Terms and Conditions) remain unaffected.",
    partyServicesThreeText:
      "Please note that some third party offers are based on data collected from you via the Flinktax website. When you accept a third party offer, you are instructing us to transfer your data to the third party. You will be informed accordingly.",
    intellectualPropertyTitle: "Intellectual property",
    intellectualPropertyOneText:
      "Definition of Intellectual Property: Intellectual property refers to all intangible assets that arise from intellectual creation, including but not limited to copyrights, trademarks, patents, trade secrets, and other intellectual property rights",
    intellectualPropertyTwoText:
      "Intellectual Property Rights: Customer acknowledges and agrees that all intellectual property rights associated with the Products or Services provided (All Content including logos, images, videos, graphics and text) are owned exclusively by Company or its licensors. The Customer does not acquire any intellectual property rights unless expressly agreed in writing.",
    intellectualPropertyThreeText:
      "Use of Intellectual Property: The Customer may only use the Company's Intellectual Property in accordance with the terms of these TOS.Any unauthorised use, copying, distribution or modification of the Intellectual Property is strictly prohibited.",
    intellectualPropertyFourText:
      "Protection of Intellectual Property: The Company will take reasonable steps to protect the Intellectual Property from theft, misuse or unauthorised use. The Customer agrees to respect the Company's intellectual property and not to take any action that may infringe the intellectual property rights",
    intellectualPropertyFiveText:
      "Disclaimer: The Company shall not be liable for any infringement of third party intellectual property by the Customer or through the use of the products or services provided. The Customer is solely responsible for compliance with all applicable laws and regulations relating to intellectual property",
    intellectualPropertySixText:
      "Intellectual Property Infringement: In the event of any infringement of the Company's intellectual property by the Customer, the Company reserves the right to take legal action and claim damages. The Customer agrees to notify the Company promptly of any Intellectual Property Infringement of which it becomes aware.",
    intellectualPropertySevenText:
      "Transfer of Rights: The Customer shall not transfer, assign or otherwise convey any of the Company's Intellectual Property Rights without the Company's prior written consent.",
    intellectualPropertyEightText:
      "Confidentiality: the Customer agrees to keep confidential and not to disclose or disclose to any third party any confidential information of the Company, including the Intellectual Property, except as expressly agreed in writing or as required by law",
    intellectualPropertyNineText:
      "Severability clause: If any provision of these GTC is or becomes invalid in whole or in part, the validity of the remaining provisions shall not be affected. The invalid provision shall be replaced by a valid provision that comes as close as possible to the economic purpose of the invalid provision.",
    dataProtectionTitle: "Data Protection",
    dataProtectionOneText:
      "The privacy policy, which is published and freely accessible on the platform, describes how Flinktax collects, processes and uses the user's personal data. The collection and processing of data is necessary for the implementation of the contract and is based on Article 6(1)(b) DSGVO.",
    dataProtectionTwoText:
      "During the usage contract, Flinktax will give the user the possibility to view his data, including information about the booked services, in the user account and give him the possibility to change or delete the data. In order to do this, the user must log in to the website with their email address and password,",
    dataProtectionThreeText:
      'Once registered, the information is passed on to a partner of the platform. From that point on, the platform partner is liable for data protection in the same way as Flinktax. With each Platform Partner, Flinktax has entered into a Data Processing Agreement in accordance with Article 28 of the GDPR ("ADV"), in which the Platform Partner is the data controller and Flinktax is the data processor.',
    dataProtectionFourText:
      "There is generally no disclosure of data to third parties unless there is a legal obligation or it is necessary for the performance of a contract. This may be the case, for example, when a payment of an accepted offer is made through a payment service provider or a loan is taken out with a bank to prepare a tax refund. The information is then passed on to the contractual partner in accordance with the order. Responsible for the processing of data are these providers and maintain with the customer through a separate contract.",
    dataProtectionFiveText:
      "As soon as the data is no longer necessary for the purpose of processing and there is no legal obligation to retain it, it will be deleted. As long as there is a contractual user relationship between the user and Flinktax, Flinktax retains the information stored on the platform. However, it may be necessary to continue to retain the data after the user relationship has ended.",
    dataProtectionSixText:
      "If the user agreement is terminated or the user's account is deleted, the user's account will be locked. If Flinktax does not need to store and process the user's personal data as a processor for the platform partner or in the case of a credit intermediation by the credit union, the data will be deleted. If the data processing is necessary for the assertion, exercise or defence of legal claims (Art. 17 (3) e) DSGVO), the data will not be deleted. There are legal time limits in which neither the platform partner nor Flinktax as processor may delete the data. The Tax Consultancy Act specifies statutory retention periods. It is required that assessment specific information is retained for a period of 10 years. It is required that invoice data or accounting documents be kept for a period of 10 years in accordance with § 147 of the Fiscal Code. At the same time, all data collected during the identification and identity check of the user pursuant to Section 8 (4) GwG must be kept for at least 5 years after the end of the business relationship. If the platform partner has an agreement on commissioned data processing (ADV) with Flinktax, Flinktax must retain the data in accordance with these retention periods. Therefore, deletion of the account is not the same as deletion of the data that Flinktax processes as the Platform Partner's processor. If the user contract is terminated or the user account is deleted, the user's data will only be deleted if the user has not yet commissioned a platform partner or the platform partner's statutory retention periods have not yet commenced. Even if the user account is not deleted, the platform partner may delete the tax data obtained through the eData retrieval. This is the case if the user does not accept the platform partner's offer and the platform partner no longer needs the tax data to perform the service. In addition, the statements in the privacy policy' apply",
    declarationConsentTitle: "Declaration of Completeness and Consent",
    declarationConsentOneText:
      "You certify that all information you provide to us is correct, accurate, current, and complete; In addition, you certify that all files and data associated with your tax return and services will be promptly transmitted.Do not take any action that defames, threatens, or harasses our employees, agents, and representatives",
    declarationConsentTwoText:
      "You confirm that you will assist us in fulfilling our obligations under the contract and agree to provide us with additional information if requested",
    declarationConsentThreeText:
      "It is your obligation to promptly notify us in writing of any changes or updates to your name, address, telephone number and email address",
    declarationConsentFourText:
      "You agree that you will not use any computer code, data mining software, robots, bots, scripts, scrapers or other similar automated or manual processes to gain access to any web pages, data, content (including User Content) or other services located on or accessible through the App. You agree that these processes will not be used to test or monitor vulnerabilities in the Services or for any other purpose. You also agree not to interfere with any authentication mechanisms or other applications necessary for the functionality of the App. Viruses, spyware or other malicious code, software or mechanisms that may interfere with the functionality of the App should not be used.",
    declarationConsentFiveText:
      "You agree not to alter or use our corporate identity, whether through logos, headers, emails or other methods, to disguise the origin or content of any messages associated with the Services.",
    declarationConsentSixText:
      "You agree not to violate any laws, statutes, ordinances or other regulations associated with the use of the App and our Services",
    declarationConsentSevenText:
      "You certify that you have confirmed your identity or any information you provide to us and that you will cooperate with any action required to authenticate you. You agree not to take any action that defames, threatens, or harasses our employees, agents, or representatives.",
    rightWithdrawTitle: "Right of withdrawal",
    rightWithdrawOneText:
      "You have the right to cancel this contract within fourteen days without giving any reason",
    rightWithdrawTwoText:
      "The withdrawal period is fourteen days from the day of the conclusion of the contract. To exercise your right of withdrawal, you must send us",
    rightWithdrawThreeText:
      "IHGP Steuer GmbH Steuerberatungsgesellschaft Oranienstr. 155,10969 Berlin, e-mail address: kanzlei@ihgp-steuer.de by means of a clear declaration (e.g. a letter sent by post, fax or e-mail) of your decision to revoke this contract. You may use the enclosed model withdrawal form for this purpose, which is, however, not mandatory. In order to comply with the withdrawal period, it is sufficient that you send the notification of the exercise of the right of withdrawal before the expiry of the withdrawal period.",
    revocationTitle: "Consequences of revocation",
    revocationOneText:
      "If you revoke this contract, we must refund all payments we have received from you without delay and at the latest within fourteen days of the day on which we received notification of your revocation of this contract. For this repayment, we will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; in no case will you be charged any fees because of this repayment.",
    disclaimerTitle: "Disclaimer",
    disclaimerOneText:
      "Flinktax GmbH does not warrant the accuracy, completeness or timeliness of the information provided on this website. We are not liable for any damages arising from the use or non-use of the information, except in the case of intent or gross negligence on our part.",
    disclaimerTwoText:
      "We accept no responsibility for the content of external websites to which we may link. The operators of the linked sites are solely responsible for their content.",
    disclaimerThreeText:
      "Use of our services is at your own risk. We do not accept any liability for any damage caused by the use of our services, except in cases of intent or gross negligence on our part.",
    damageClaimsTitle: "Damage claims",
    damageClaimsOneText:
      "Flinktax is not obliged to assist in the settlement of claims. The enforcement of claims against the platform partner is the responsibility of the user",
    termContractTitle: "Term, termination of the service contract",
    termContractOneText:
      "After the user has been registered and the use of the platform has been activated by Flinktax, the user contract comes into force and ends for an indefinite period. At any time it can be duly announced for both sides. It is necessary to send the notice of termination by e-mail to info@flinktax.de. Disconnection of the user account in the user dashboard also counts as termination.",
    termContractTwoText:
      "Flinktax has the right to irrevocably delete the user account as soon as the user contract is terminated. The responsibility for personal data and other information then lies with the respective platform partner.",
    termContractThreeText:
      "If there is an important reason, in particular if one violates these GTC and other contractual obligations, Flinktax reserves the right to terminate the user relationship immediately and to block the user account if it is unreasonable to continue with the contract.",
    disputeResolutionTitle:
      "Alternative dispute resolution according to Art. 14 para. 1 ODR-VO and § 36 VSBG.",
    disputeResolutionOneText:
      "The EU Commission has created an internet platform for online dispute resolution. This platform serves as a contact point for the out-of-court settlement of disputes concerning contractual obligations arising from online sales contracts. More information is available at the following link: http://ec.europa.eu/consumers/odr. Flinktax is neither obliged nor willing to participate in a dispute resolution procedure before a consumer arbitration board.",
    damageClaimsOneText:
      "Flinktax, taleplerin çözümüne yardımcı olmakla yükümlü değildir. Platform ortağına karşı taleplerin yerine getirilmesi kullanıcının sorumluluğundadır",
    termContractTitle: "Hizmet sözleşmesinin süresi, feshi",
    termContractOneText:
      "Kullanıcı kaydedildikten ve platformun kullanımı Flinktax tarafından etkinleştirildikten sonra, kullanıcı sözleşmesi yürürlüğe girer ve belirsiz bir süre için sona erer.Herhangi bir zamanda her iki taraf için de usulüne uygun olarak ilan edilebilir.Fesih bildiriminin info@flinktax.de adresine e-posta ile gönderilmesi gerekmektedir.Kullanıcı kontrol panelindeki kullanıcı hesabının bağlantısının kesilmesi de fesih olarak sayılır",
    termContractTwoText:
      "Flinktax, kullanıcı sözleşmesi feshedilir feshedilmez kullanıcı hesabını geri alınamaz bir şekilde silme hakkına sahiptir.Kişisel verilerin ve diğer bilgilerin sorumluluğu daha sonra ilgili platform ortağına aittir.",
    termContractThreeText:
      "Önemli bir neden varsa, özellikle bu GŞK ve diğer sözleşme yükümlülükleri ihlal edilirse, Flinktax kullanıcı ilişkisini derhal feshetme ve sözleşmeye devam etmek makul değilse kullanıcı hesabını engelleme hakkını saklı tutar,disputeResolutionTitle: 'Madde 14 paragrafına göre alternatif uyuşmazlık çözümü.14 para.1 ODR-VO ve § 36 VSBG.',disputeResolutionOneText:'AB Komisyonu, çevrimiçi uyuşmazlık çözümü için bir internet platformu oluşturmuştur.Bu platform, çevrimiçi satış sözleşmelerinden kaynaklanan sözleşmeden doğan yükümlülüklerle ilgili anlaşmazlıkların mahkeme dışı çözümü için bir irtibat noktası olarak hizmet vermektedir. Daha fazla bilgiye aşağıdaki linkten ulaşabilirsiniz: http://ec.europa.eu/consumers/odr.Flinktax, tüketici hakem heyeti nezdinde bir uyuşmazlık çözüm prosedürüne katılmakla ne yükümlüdür ne de buna isteklidir",
  },
  username: "Username",
  usernameValidationRequiredText: "Please enter username...",
  passwordValidationRequiredText: "Please enter your password",
  customers: "Customers",
  admins: "Admins",
  menu: "Menu",
  noFoundData: "No data found.",
  fullname: "Name Surname",
  status: "Status",
  viewFiles: "View files",
  allStatus: "Show all",
  waiting: "Waiting",
  reviewing: "Reviewing",
  completed: "Completed",
  canceled: "Canceled",
  pendingConfirmation: "Waiting for release - Manager",
  transmittedFa: "Transmitted to the FA",
  customersDescription:
    "The list of customers who have joined the application is listed below.",
  managersDescription:
    "The list of personnel involved in the application is listed below.",
  documentsDescription:
    "Select the document of the user that you want to review.",
  searchCustomerPlaceholder:
    "You can search by name, surname, email and phone.",
  searchCampaingText: "The campaign...",
  noFoundDocumentDescription:
    "We could not find the file you want to download.",
  close: "Close",
  phone: "Telephone",
  filterByStatus: "Filter by status",
  createdAt: "Date of registration",
  taxIdentifyNo: "Tax identification number",
  taxYear: "Tax Year",
  accountInfo: "Account Information",
  addressInfo: "Address Information",
  number: "No",
  activeUser: "Active account",
  passiveUser: "Passive account",
  seeDetail: "See detail",
  foundFile: "{length} file found.",
  pleaseAcceptTerms: "Filling this field is mandatory.",
  wrongCodeInRegister: "You have entered an incorrect code.",
  clearSignature: "Clear signature",
  deleteDocument: "Your file has been deleted.",
  noDeleteDocument: "Your file could not be deleted.",
  ibanFormat: "The iban format entered is incorrect.",
  careerText: "Tax Controller or Account Manager",
  careerTaxText: "Then apply now as:",
  careerButton: "Apply now",
  send: "Send",
  sendEmailText:
    "We have sent you a link to your email address so that you can reset your password.",
  matchEmail: "There are no accounts matching this email address.",
  notSendEmailText:
    "A link could not be sent to your e-mail address to renew your password.",
  newPassword: "New Password",
  againNewPassword: "New Password Again",
  renewedPassword: "Your password has been renewed.",
  notRenewedPassword: "Your password could not be renewed.",
  forgotPassword: "Forgot Password",
  fancyChallange: "Fancy a new challenge?",
  againSmsCode: "The sms code has been sent again.",
  notAgainSmsCode: "The sms code could not be resent.",
  notSuccessPayment: "Your payment could not be completed.",
  successPayment: "Your payment has been completed.",
  errorMessage: "failing",
  successMessage: "successful",
  transaction: "Your transaction was ",
  transactionOneText: "In case of any problems, you can contact us at ",
  transactionTwoText: "",
  payment: {
    paymentOneText: "We have received all the relevant information.",
    paymentTwoText:
      "Our tax experts will evaluate your documents and inform you shortly.",
    taxPrice: "Payment of the tax return",
    taxPriceButton: "Pay now",
    paymentInfoText:
      "*If the calculated tax refund is more than €599.99, you will be asked to pay the balance via a separate email. The total fee for Flinktax services will never exceed 20% of your tax refund.",
    creditCard: "Credit card, G-pay, Apple Pay",
  },
  years: "Years",
  yearsDescription: "{year} year is listed below.",
  documentsOfYear: "Documents for the year {year}",
  comeBackPayment: "You are redirected back to the payment page...",
  comeBackWebsite: "You are redirected back to the site...",
  authorised: "Authorised",
  admin: "Admin",
  manager: "Manager",
  adminAdd: "Add Admin / Manager",
  saveSuccess: "Your registration is complete.",
  notSaveSuccess: "Your registration is incomplete.",
  sameAdmin: "There are users matching this email address.",
  actions: "Operations",
  successAdminMessage: "Your transaction has been successfully completed.",
  notSuccessAdminMessage: "Your transaction has not been realised.",
  accountStatus: "Account Activity",
  adminPanel: "Admin Panel",
  dataSettings: "Data protection settings",
  dataSettingsText:
    "We use cookies and similar technologies (tools) that are necessary for the operation of our website. With your consent, we also use optional tools for usage analysis, marketing purposes and integration of external content. Your consent also includes the transfer of data to third countries that do not have a comparable level of data protection to the EU. If personal data is transferred to these countries, there is a risk that the authorities will collect and analyse this data and your data subject rights will not be enforced. You can revoke your consent to data processing and transfer at any time and deactivate the tools. You can find more details on this in our privacy policy. If you are under 16 years of age and wish to consent to voluntary services, you must obtain the consent of your parent or guardian. We use cookies and other technologies on our website. Some of these are essential, others help us to improve this website and your experience. Personal data (e.g. IP addresses) may be processed, for example for personalised advertising and content or for advertising and content measurement. For more information on how we use your data, please see our privacy policy. You can cancel or adjust your choice at any time under Settings.",
  optionalCookie: "Accept all optional cookies",
  rejectOptionalCookie: "Decline optional cookie",
  selectCookie: "Select cookies",
  noSearchAdmin: "No admin named",
  noSearchAdminTwo: "was found.",
  callAdmin: "Search..",
  selectAdmin: "Select",
  cookieSettings: "Cookie Policy",
  sameAddress: "Use the same address",
  noSameAddress: "Enter a different address",
  apply: "Apply",
  enterDiscountCode: "Enter Discount / Company code",
  campaigns: "Campaigns",
  campaignsText: "The list of campaigns in the application is listed below.",
  campaignsUserText:
    "The list of customers using the campaign in the application is listed below.",
  discountCodeName: "Discount Code Name",
  discountCode: "Discount Code",
  expiredAt: "End Date",
  appointedPerson: "Appointed Person",
  addCampaigns: "Add Campaign",
  indefinitely: "Indefinitely",
  campany: {
    campaignName: "Campaign Name",
    campaignCodeName: "Enter the name of the discount code.",
    appointedName: "Assigned Name",
    nameDiscountCode: "Enter the name to assign the discount code.",
    discountType: "Discount Type",
    percentType: "Percent Type",
    priceType: "Price Type",
    discountAmountRate: "Discount Amount / Discount Rate",
    discountAmountRateEnter: "Enter discount amount / rate.",
    campaignDescription: "Campaign description",
    descriptionEnter: "Enter description",
    campaignActive: "Campaign Activity",
    taxStatus: "Tax Status",
    campaignUsers: "Customers Using the Campaign",
    discountSuccess: "Your code has been successfully applied ",
    discountNoSuccess: "Your code could not be applied.",
  },
  taxInfo:
    "Once a year, you will receive a Income Tax Certificate from your employer. Here you will find all the information about your tax deductions and social security contributions, your gross salary and many other deductions that your employer has already paid via the monthly payslip at a glance. Upload your income tax certificate here.",
  incomeInfo:
    "Investment Income represent the profits resulting from various types of financial investments. Examples include gains from the sale of shares, interest income from savings accounts or dividends earned from the ownership of shares or shares in a limited company. Upload your bank statements here. Upload your bank confirmations here",
  expensesInfo:
    "Professional expenses include all expenses that are directly related to your professional activity. If these costs are not reimbursed by your employer, you have the option of claiming them in your tax return.",
  movingCostsInfo:
    "If you have changed your place of residence for professional reasons, it is possible to deduct the costs of the move as income-related expenses. This means that your taxable income in Germany will be reduced. This rule applies both to relocations from another country to Germany and to relocations within Germany.",
  childrenInfo: "",
  insurancesInfo:
    "Almost all types of insurance can be claimed for tax purposes. These include: Pension insurance, health and long-term care insurance, supplementary health insurance, such as for dental prostheses, foreign health insurance, supplementary long-term care insurance Unemployment insurance, accident insurance, liability insurance, motor vehicle liability insurance, term life insurance Endowment insurance - provided it was taken out before 2005, death benefit insurance, legal expenses insurance",
  contributionsInfo:
    "In Germany, you have the option of deducting your donations or membership fees as special expenses for tax purposes, provided the tax office recognises the charitable status of the organisation to which you donate. You can claim up to 20% of your total income as special expenses.",
  revenuesFromAbroadInfo:
    "If Germany is your place of residence or habitual abode, you are subject to unlimited tax liability in Germany. In the case of unlimited tax liability, the global income principle applies. This means that all your income, including income from abroad such as salary from foreign employers or rental income from property abroad, is taxable in Germany.",
  otherInfo:
    "Operating cost accounting/housing allowance accounting, Medical expenses, Disability - severely handicapped pass necessary, Care costs - person, degree of care, address and date of birth, Funeral costs",
  useCookie: "We use cookies",
  useCookieText:
    'In order to provide you with a better service, cookies are used to collect information that we use to improve our website and provide you with relevant information. You can read more about this in our privacy policy. Clicking "Agree" allows us to process this data and share it with third parties in accordance with our privacy policy.',
  necessary: "Necessary",
  necessaryText:
    "These technologies are required to enable the core functionality of the website.",
  marketing: "Marketing",
  marketingText:
    "These cookies allow us to show you relevant and interesting marketing. In addition, we can ensure that you do not see the same adverts more than once. If you disable these cookies, you will still see ads, but they will not be relevant and therefore of less interest to you.",
  statistic: "Statistics",
  statisticText:
    "To improve our service and website, we collect anonymised data for statistics and analysis. With this data we learn to understand how our service can be used and improved.",
  agree: "Agree",
  docStatus: "Tax Declaration Status:",
  ibanInfoText:
    "It is necessary to provide your IBAN information so that any tax refunds from the tax office can be transferred directly to your account.",
  taxNoText:
    "Once you have completed all the steps and sent us your documents, you can add your tax ID to your profile.",
  returnHomePage: "Back to home page",
  returnHomePageMessage:
    "If you return to the home page, all the information you have entered will be lost. Do you want to return?",
  giveUp: "Give up",
  companyEmployed: "Company Employed",
  paymentPartner: "Spouse Tax Declaration Status",
  assess: "Joint assessment ",
  assessment: "No joint assessment",
  companyName: "Company Name",
  notFoundCompany: "There are no companies.",
  childInformation: "Child Knowledge",
  nameSurname: "Name-Surname",
  birthDate: "Birthday",
  notFoundChild: "There are no children.",
  userNote: "User Note",
  notFoundNote: "No note found.",
  child: "Child",
  notAddChildren: "You did not add children.",
  informationCompltely: "Please fill in the information completely.",
  cancelCondition: "Cancellation conditions",
  uploadDocumentNote:
    "Would you like to share information about the documents you uploaded? (You can enter a maximum of 100 characters).",
  uploadDocumentNoteError:
    "You cannot add a note because you are not uploading a file.",
  paymentOneText: "I accept the",
  paymentTwoText: "",
  zuu: "",
  deleteCustomer: "The client has been successfully deleted.",
  noDeleteCustomer: "The customer could not be deleted.",
  questionDeleteCustomer:
    "The customer will be deleted. Do you authorise the transaction?",
  areYouSure: "Are you sure?",
  success: "Successful",
  failed: "Failed",
  ok: "Ok",
  marriedStatusText: "Joint taxation with the spouse (Zusammenveranlagung)",
  marriedStatusInfoTitle: "Joint Filing",
  marriedStatusInfoText: "Joint tax return for both spouses.",
  marriedStatusInfoText2:
    "Benefits: Lower taxes through income splitting, shared allowances.",
  marriedStatusInfoText3: "Fee: Only 169 euros.",
  company: "Company",
  campaing: "Campaign",
  successCompanyCode: "Your company code has been applied.",
  notSuccessCompanyCode: "Your company code could not be applied.",
  codeType: "Code Type",
  companyCode: "Company Code",
  campaignCode: "Campaign Code",
  dashboardInfo: "The average income tax refund amounts to EUR 1,095",
  notTaxReturn: "We do not guarantee a tax refund",
  sourceText: "Source: Federal Statistical Office",
  fileNotFound:
    "You cannot proceed to the payment step because your files were not uploaded correctly. Please upload your files again.",
  signatureMessage: "!!! Please do not leave the area when signing in.",
  myTaxReturn: "My Tax Returns",
  notes: "Notes",
  addNote: "Add Note",
  updateNote: "Update Note",
  enterNote: "Enter note",
  filterYear: "Filter by year",
  viewAllTaxReturn: "View all tax returns",
  paymentSucceed: "Payment has been made.",
  thereIsNoPayment: "No payment.",
  paymentSucceedUser: "Payers",
  paymentNoSucceedUser: "Non-payers",
  users: "Registrants",
  usersDescription:
    "The list of users registered to the application is listed below.",
  noCompleteCustomer:
    "We couldn't complete your transaction. Please try again later.",
  goToProfile: "Go to profile",
  newTax: "New Tax Return",
  toPay: "Payments",
  newTaxDescription: "You can file your new tax returns by year here.",
  toPayDescription:
    "You can make payments for the tax returns you have previously applied for here.",
  payIt: "Pay it",
  taxWaiting: "in progress...",
  taxWaitingDescription:
    "We are continuing your tax declaration process, you will be able to continue the payment process when your transactions are completed.",
  paySucceed: "Your payment has been completed.",
  applyTaxDescription: "There is a tax return you applied for on {date}.",
  taxYearListTitle: " <div  class='mt-4 mb-0'>for year</div><h1>{year}</h1>",
  taxDoneDescription:
    "Great! Your tax return is ready, you can proceed to payment.",
  paymentSucceedDescription:
    "Great! Your tax return is ready, you can complete the transaction by completing the payment process below.",
  paymentTitle: "The tax returns you have applied for are listed below.",
  notAuthForPayment: "You are not authorized for payment.",
  infoPayment: "You are not authorized for the payment.",
  goToAccount: "Go to your account",
  welcome: "Hello",
  paymentWaiting: "Payment pending",
  copied: "Copied",
  trashUsers: "Deleted Users",
  recover: "Recover",
  questionRescueCustomer:
    "You are about to reactivate the deleted user, do you approve this action?",
  rescueUsers: "Deleted Users",
  rescueUsersDescription: "Lists users deleted from your application",
  rescueUserUpdatedSuccess:
    "The customer has been successfully transferred back into the application.",
  plainText: "Transactions",
  createdDate: "Created Date",
  plainTextDescription: "Lists the processes in the application",
  choosedUser: "{count} users selected.",
  chooseAll: "Select all ({count} users)",
  gettingAllData: "Getting all data... please wait",
  exportToExcel: "Export to Excel",
  registeredDateOfCustomer: "Customer registration date / Gender",
  whatsyourGender: "What is your gender?",
  male: "Male",
  female: "Female",
  other: "I don't want to specify",
  genderLong: {
    1: "Male",
    2: "Female",
    3: "I don't want to specify",
  },
  gender: {
    1: "M",
    2: "F",
    3: "O",
  },
  genderExcell: {
    1: "Herr",
    2: "Frau",
    3: "-",
  },

  amount: "Amount",
  payerFullname: "Collector",
  homeOfficeDescription: "Works from home {count} days a week.",
  areyouWorkingAsHomeOffice: "Are you working from Home Office / Remote?",
  homeOfficeDayCount:
    "How many days a week do you work from Home Office / Remote?",
  paymentOption: "Please select payment type",
  dontForgetSpousesTax:
    "Don't forget to upload your spouse's tax-related documents.",
  uploadFile: "Upload File",
  continue: "Continue",
  info: "Information",
  lastCallForUpload: "After this step, the document upload will only be possible via email to mail@flinktax.de. Do you want to upload any more documents right now? ",
  toPayDescription2: "Do you have a missing document or want to add a document later? <br> You can send your documents via email to <a href='mailto:mail@flinktax.de'>mail@flinktax.de</a>. "
};
