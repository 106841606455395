import localStorageManager from "../../services/localStorageManager";
import router from "../../router";

export default {
  state: {
    activeUser: {},
    autoLogin: {
      status: false,
      email: "",
      password: "",
    },
  },
  getters: {
    activeUser: (state) => state.activeUser,
    getAutoLogin: (state) => state.autoLogin,
  },
  mutations: {
    SET_ACTIVE_USER(state, payload) {
      state.activeUser = payload;
    },
    SET_LOGOUT(state, payload) {
      localStorageManager.userAuth.removeUser();
      state.activeUser = {};
      let roleType = localStorageManager.userAuth.getLoginRoleType();
      if (roleType == "Admin" || roleType == "Manager") {
        router.push("/admin/anmeldung");
      } else {
        router.push("/anmeldung");
      }
    },
    SET_AUTO_LOGIN(state, payload) {
      state.autoLogin = {
        status: true,
        ...payload,
      };
    },
    CLEAR_AUTO_LOGIN(state) {
      state.autoLogin = {
        status: false,
        email: "",
        password: "",
      };
    },
  },
  actions: {
    SET_ACTIVE_USER_ACTION({ commit }, payload) {
      commit("SET_ACTIVE_USER", payload);
      return true;
    },
    SET_LOGOUT_ACTION({ commit }) {
      commit("SET_REDIRECT_URL", router.app.$route);
      commit("SET_LOGOUT");
    },
  },
};
