var CryptoJS = require("crypto-js");

export const crypto = (data) => {
    let cryptoText = CryptoJS.AES.encrypt(data, 'key').toString();
    return cryptoText;
}

export const decrypto = (data) => {
    let bytes = CryptoJS.AES.decrypt(data, 'key');
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}
