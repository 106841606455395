<template>
  <div id="app">
    <!-- Loader -->
    <!-- <div id="preloader" v-if="loading">
      <div id="status">
        <div class="spinner"></div>
      </div>
    </div> -->
    <router-view v-if="showApp" />
    <div class="d-none d-lg-block">
      <div
        class="row cookie-content w-100 ml-0 align-items-center"
        v-if="showCookie"
      >
        <div class="col-12 col-md-1 col-xl-1">
          <img src="../src/assets/img/small-logo.png" alt="" height="60" />
        </div>
        <div class="col-12 col-md-8 col-xl-8">
          <h5>{{ $t("dataSettings") }}</h5>
          <p class="font-size-12">
            {{ $t("dataSettingsText") }}
          </p>
        </div>
        <div
          class="col-12 col-md-3 col-xl-3 d-flex flex-column justify-content-center"
        >
          <button class="blue-button mb-3" @click="saveCookie">
            {{ $t("optionalCookie") }}
          </button>
          <button class="gray-button mb-3" @click="noSaveCookie">
            {{ $t("rejectOptionalCookie") }}
          </button>
          <button
            class="black-button mb-3"
            @click="cookieShowModal = !cookieShowModal"
          >
            {{ $t("selectCookie") }}
          </button>
        </div>
      </div>
    </div>
    <div class="d-block d-lg-none">
      <div class="cookie-content w-100 ml-0" v-if="showCookie">
        <div class="mb-4">
          <h5 class="font-size-12">{{ $t("dataSettings") }}</h5>
          <router-link to="/cookie" class="font-size-12 cookie-link">{{
            $t("cookieSettings")
          }}</router-link>
        </div>
        <div class="font-size-12 d-flex flex-column justify-content-center">
          <button class="blue-button mb-3" @click="saveCookie">
            {{ $t("optionalCookie") }}
          </button>
          <button class="gray-button mb-3" @click="noSaveCookie">
            {{ $t("rejectOptionalCookie") }}
          </button>
          <button
            class="black-button mb-3"
            @click="cookieShowModal = !cookieShowModal"
          >
            {{ $t("selectCookie") }}
          </button>
        </div>
      </div>
    </div>
    <b-modal
      id="cookie-modal"
      centered
      :title="$t('selectCookie')"
      v-model="cookieShowModal"
      hide-footer
    >
      <h6 class="mb-2">{{ $t("useCookie") }}</h6>
      <p class="mb-5">
        {{ $t("useCookieText") }}
      </p>
      <label>
        <span class="label">
          <h6>{{ $t("necessary") }}</h6>
          <p>
            {{ $t("necessaryText") }}
          </p>
        </span>
        <input
          class="sr-only"
          type="checkbox"
          name="my-toggle1"
          v-model="cookiesSelect.notwendig"
        />
        <div class="slider"></div>
      </label>
      <label>
        <span class="label">
          <h6>{{ $t("marketing") }}</h6>
          <p>
            {{ $t("marketingText") }}
          </p>
        </span>
        <input
          class="sr-only"
          type="checkbox"
          name="my-toggle2"
          v-model="cookiesSelect.marketing"
        />
        <div class="slider"></div>
      </label>
      <label>
        <span class="label">
          <h6>{{ $t("statistic") }}</h6>
          <p>
            {{ $t("statisticText") }}
          </p>
        </span>
        <input
          class="sr-only"
          type="checkbox"
          name="my-toggle3"
          v-model="cookiesSelect.statistics"
        />
        <div class="slider"></div>
      </label>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <button class="gray-button mr-2" @click="noSaveCookie">
          {{ $t("rejectOptionalCookie") }}
        </button>
        <button class="blue-button" @click="saveCookieSelect">
          <span class="font-weight-bold">{{ $t("agree") }}</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import localStorageManager from "./services/localStorageManager";
import { decrypto } from "./utils/Crypto";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: true,
      showCookie: true,
      showApp: false,
      cookieShowModal: false,
      cookiesSelect: {
        notwendig: false,
        marketing: false,
        statistics: false,
      },
    };
  },
  methods: {
    ...mapActions(["SET_ACTIVE_USER_ACTION"]),
    detectMob() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    saveCookieSelect() {
      localStorage.setItem("cookiesAction", this.cookiesSelect);
      this.showCookie = false;
      this.cookieShowModal = false;
    },
    saveCookie() {
      localStorage.setItem("cookiesAction", true);
      this.showCookie = false;
    },
    noSaveCookie() {
      localStorage.setItem("cookiesAction", "no");
      this.showCookie = false;
      this.cookieShowModal = false;
      this.cookieAnalytics();
    },
    cookieAnalytics() {
      var gaProperty = "UA-XXXXXX-Y";
      var disableStr = "ga-disable-" + gaProperty;
      if (document.cookie.indexOf(disableStr + "=true") > -1) {
        window[disableStr] = true;
      }
      document.cookie =
        disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
      window[disableStr] = true;
    },
    changeDirection() {
      document.body.style = "direction:rtl !important";

      
    },

    setLang() {
      const q = this.$route.query.lang;
      const lang = localStorage.getItem("lang");
      if (q) {
        if (q === "ar") this.changeDirection();
        this.$i18n.locale = q;
        localStorage.setItem("lang", q);
      } else if (lang) {
        if (lang === "ar") this.changeDirection();
        this.$i18n.locale = lang;
      } else {
        this.$i18n.locale = "tr";
      }
      this.showApp = true;
    },
  },
  mounted() {
    setTimeout(() => {
      this.setLang();
    }, 150);
    this.$store.commit("setWebView", this.detectMob());
    const activeuser = localStorageManager.userAuth.getUser();
    if (activeuser) {
      const decryptoData = decrypto(activeuser);
      this.SET_ACTIVE_USER_ACTION(decryptoData);
    }
    const cookie = localStorage.getItem("cookiesAction");
    if (cookie || cookie == "no") {
      this.showCookie = false;
    } else {
      this.showCookie = true;
    }
    setTimeout(() => {
      // this.$store.commit(setWebView, window.ReactNativeWebView);
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          message: "requestCheck",
          answer: this.$route.query?.type,
        })
      );
    }, 4000);
  },
};
</script>
<style lang="scss">
.cookie-content {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 20px 0px;
  z-index: 100;
  border-top: 1px solid #e7e7ec;
  // max-height: 200px !important;
  // overflow: scroll;
}

@media screen and (max-width: 768px) {
  .cookie-content {
    padding: 20px 30px;

    .cookie-link {
      text-decoration: underline !important;
      color: #57c6ff;
    }
  }
}
</style>
