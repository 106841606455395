import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { en } from './locales/en';
import { tr } from './locales/tr'
import { de } from './locales/de'
import { ar } from './locales/ar'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'tr',
    messages: { 'en': en, 'tr': tr, 'de': de, 'ar': ar },
});

export default i18n;